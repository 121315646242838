:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: rgba(255,255,255,0.5);
    --header-padding: 0.5em 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #89ab15;
    --topmenu-color: white;
    --topmenu-li-a-padding: 8px 15px;

        /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.black {
    background-color:#000;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:91vh;
}

/* header
---------------------------------------------------------*/
header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:100;
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    /*box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);*/
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0 0 0 0.5em;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
    font-weight:700;
    text-transform:uppercase;
    font-size:0.9rem;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
    border-radius:5px;
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.logo a img {
    max-width:70%;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin:0 auto;
    position: relative;
}
.slick-track {
    height: 100vh;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.bildspel_titel  {
    width:100%;
    background:rgba(255,2555,255,0.3);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align:center;
    color:#fff;
    display:block;
    margin: 0 auto;
}

.bildspel_titel .container {
    max-width:880px;
    font-size: 1.3rem;
}

.bildspel_titel span {
    display:block;
}

.bildspel_titel .slider-heading {
    font-weight:700;
    font-size:1.7rem;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:10px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#89ab15;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#89ab15;
    display:block;
}

.slick-dots li {
    border:#89ab15 solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}


/* start-block-wrapper
---------------------------------------------------------*/

.start-block-wrapper {
    position:relative;
}

.start-block-wrapper .introtext {
    position:absolute;
    left:0;
    right:0; 
    top:40vh;
    text-align:center;
    max-width:800px;
}

.start-block-wrapper .introtext h1, .start-block-wrapper .introtext h2 {
    color:white;
}

.start-block-wrapper .introtext h1 {
    font-weight:800;
    text-transform:uppercase;
    letter-spacing:-1px;
    font-size:2.8rem;
    margin-bottom:0.5em;
}

.start-block-wrapper .introtext {
    color:white;
}

/* page
---------------------------------------------------------*/
.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color:black;
    height:100vh;
}

header.change {
    background:white;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.page .content {
    color:white;
}

.content {
  position: relative;
    background:rgba(0,0,0,0.5);
    padding:0 !important;
    border:rgba(255, 255, 255, 0.21) solid 1px
}

.art-galleri {
    background:black;
    box-shadow: 0 0 0 0.5px #313030;
}

.art-galleri-inner {
    padding:1.5em;
}

.art-galleri ul {
    list-style:none;
    margin:0;
    padding:0;
}

.art-galleri li {
    box-shadow: 0 0 0 2px #000;
}

.gallery-page {

}

.gallery-items {
        text-align: justify;
    column-gap: 3px;
    -webkit-column-gap: 6px;
    -webkit-columns: 4;
    -moz-columns: 4;
    columns: 4;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #89AB15;
    clear: both;
}

/* Footer
---------------------------------------------------------*/
.startsida  footer {
    display:none;
}

footer {
    color:#b7b7b7;
    text-align:center;
    
}

footer a {
    color: #b7b7b7;
    text-decoration:none;
}

footer a:hover {
    color:#fff;
}

footer ul {
    margin:1em 0 0 0;
    padding:0;
    list-style:none;
}

footer .contact li {
    display:inline-block;
    padding:0 1em;
    border-right:#383838 solid 1px;
}

footer .contact li:last-child {
    border-right:none;
}

footer .social {
    border-top:#383838 solid 1px;
}

footer .social li {
    border:none;
    padding:0 1em;
    display:inline-block;
}

footer .social li a {
    font-size:1.8rem;
}

.created-by {
    background:#1D1D1D;
    padding:0.8em 0;
    font-size:0.8rem;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.adress span, .c-info span, .adress span, .c-info a{
    display:block;
    text-decoration:none;
}

.c-info {
    margin-top:1em;
}

.c-info a {
    color:white;
}

.c-info a:hover {
    color:#89AB15;
}

/* other
---------------------------------------------------------*/

.boltform label {
    display: none;
}

.boltform textarea {
    min-height:120px;
}

.fa-map-marker:before {
    font-size:3.4rem;
}

.mapContent {
    color:black;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#89AB15;
    border:#89AB15 solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

button[type=submit], input[type=submit] {
    background:#89AB15;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#89AB15;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    font-family: OpenSans;
    font-weight: 400;
    color:#282d32;
}

@-webkit-keyframes fadeIn { from { opacity:0.4; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0.4; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0.4; } to { opacity:1; } }

body {
    opacity:0;  /* make things invisible upon start */
    -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
 
    -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
 
    -webkit-animation-duration:0.9s;
    -moz-animation-duration:0.9s;
    animation-duration:0.9s;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#89ab15;
    margin-top:0;
}


h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-weight:700;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;    
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {
    .start-block-wrapper .introtext h1 {
        font-size:2.0rem;
    }
    .start-block-wrapper .introtext {
    bottom:0vh;
    }
    .introtext .block2-content {
        font-size:0.9rem;
    }
}

@media (max-width: 640px) {
    .start-block-wrapper .introtext {
    bottom:5vh;
    }
    .gallery-items {
        text-align: justify;
        column-gap: 3px;
        -webkit-column-gap: 3px;
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
    }
    footer .contact li {
        border:none !important;
    }
}

@media (max-width: 800px) {}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show {
        background:white;
    }
    .menu {
        background:white !important;
    }
    #menu-icon {
        position:absolute;
        top:10px;
        right:10px;
    }
    .top-img {
        background-size:contain;
        background-repeat:no-repeat;
        background-position:top center;
        height:auto;
    }
    .page .content {
        margin-top:30vh;
    }
    .content {
        border:none;
        padding:1.5em !important;
    }
    .gallery-items {
        text-align: justify;
        column-gap: 3px;
        -webkit-column-gap: 3px;
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
    }
            
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}
